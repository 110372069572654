@import 'variables';

/* Full HD - TV Layout: Common */
@media only screen and (min-width: 2200px) and (max-width: 4000px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1800px;
  }
  @each $size in $font-sizes {
    .fs-#{$size} {
      font-size: #{$size * (70/64)}px;
    }
  }

  @each $height in $line-heights {
    .lh-#{$height} {
      line-height: #{$height * (70/64)}px;
    }
  }
  @each $radius in $border-radius {
    .radius-#{$radius} {
      border-radius: #{$radius * (70/64)}px !important;
    }
    .radius-top-left-#{$radius} {
      border-top-left-radius: #{$radius * (70/64)}px !important;
    }
    .radius-top-right-#{$radius} {
      border-top-right-radius: #{$radius * (70/64)}px !important;
    }
    .radius-bottom-left-#{$radius} {
      border-bottom-left-radius: #{$radius * (70/64)}px !important;
    }
    .radius-bottom-right-#{$radius} {
      border-bottom-right-radius: #{$radius * (70/64)}px !important;
    }
  }

  .hero-image {
    width: 760px;
  }
  .client-image {
    height: 150px;
  }
  .service-icon {
    height: 100px;
  }
  .testimonial-person-image {
    height: 200px;
    width: 200px;
  }
  .testimonial-list-slider-area .swiper-pagination {
    margin-top: 20px;
  }
  .feature-icon {
    height: 90px;
  }
  .blog-image {
    height: 260px;
  }

  .footer-shape {
    height: calc(100% + 100px);
    width: 44%;
    left: 0px !important;
  }
  .footer {
    margin-top: 130px;
    padding-top: 60px !important;
    padding-bottom: 90px !important;
  }
  .pricing-feature-list ul li .icon {
    margin-top: 6px;
  }
  .pricing-box-area {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .product-details-image {
    height: 430px;
  }
  .single-career-image {
    height: 700px;
  }
}
/* Full HD - TV Layout: 2560px. */
@media only screen and (min-width: 2560px) and (max-width: 4000px) {
}
/* Full HD - Small TV Layout: 2400px. */
@media only screen and (min-width: 2200px) and (max-width: 2559px) {
}
/* HD Layout: 1920px. */
@media only screen and (min-width: 1601px) and (max-width: 2200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1500px;
  }
  @each $size in $font-sizes {
    .fs-#{$size} {
      font-size: #{$size * (64/64)}px;
    }
  }

  @each $height in $line-heights {
    .lh-#{$height} {
      line-height: #{$height * (64/64)}px;
    }
  }
  @each $radius in $border-radius {
    .radius-#{$radius} {
      border-radius: #{$radius * (60/64)}px !important;
    }
    .radius-top-left-#{$radius} {
      border-top-left-radius: #{$radius * (64/64)}px !important;
    }
    .radius-top-right-#{$radius} {
      border-top-right-radius: #{$radius * (64/64)}px !important;
    }
    .radius-bottom-left-#{$radius} {
      border-bottom-left-radius: #{$radius * (64/64)}px !important;
    }
    .radius-bottom-right-#{$radius} {
      border-bottom-right-radius: #{$radius * (64/64)}px !important;
    }
  }

  .hero-image {
    width: 580px;
  }
  .product-software-area .hero-content {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .product-software-area .hero-image {
    width: 670px;
  }

  .client-image {
    height: 110px;
  }
  .service-icon {
    height: 80px;
  }
  .testimonial-person-image {
    height: 180px;
    width: 180px;
  }
  .testimonial-list-slider-area .swiper-pagination {
    margin-top: 20px;
  }
  .feature-icon {
    height: 70px;
  }
  .blog-image {
    height: 260px;
  }

  .footer-shape {
    height: calc(100% + 60px);
    width: 44%;
    left: 0px !important;
  }
  .footer {
    margin-top: 100px;
  }

  .py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pricing-feature-list ul li .icon {
    margin-top: 6px;
  }
  .pricing-box-area {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .product-details-image {
    height: 400px;
  }
  .single-career-image {
    height: 700px;
  }
}
/* Laptop with HDPI: 1440px */
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .hero-image {
    width: 590px;
  }

  .footer-shape {
    height: calc(100% + 60px);
    width: 44%;
    left: 0px !important;
  }
  .testimonial-list-slider-area .swiper-pagination {
    margin-top: 15px;
  }
}
/* Normal PC: 1366px */
@media only screen and (min-width: 1301px) and (max-width: 1399px) {
}
/* Laptop with MDPI: 1280px */
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .services-area-feature .container {
    padding: 0 100px;
  }
}

/* Medium Layout(Vertical): 1080px. */
@media only screen and (max-width: 1200px) {
  @each $size in $font-sizes {
    .fs-#{$size} {
      font-size: #{$size * (40/64)}px;
    }
  }

  @each $height in $line-heights {
    .lh-#{$height} {
      line-height: #{$height * (40/64)}px;
    }
  }
  .section-newsletter {
    padding: 15px !important;
    width: 100%;
  }
  .section-outline {
    padding: 15px !important;
    width: 100%;
  }
  .share-area {
    display: none !important;
  }
  .blog-mobile-share-area {
    display: block;
  }
  .footer-shape {
    height: calc(100% + 50px);
    width: 53%;
  }
  .footer {
    margin-top: 60px;
  }
  .header-logo,
  .footer-logo {
    height: 35px;
  }
  .footer-links ul li {
    margin-bottom: 0 !important;
  }
  .footer-links h6 {
    margin-bottom: 5px !important;
  }
  .footer-logo:before {
    width: 240px;
  }
  .footer-description {
    padding-right: 35px;
  }
  .product-software-area .hero-image {
    width: 420px;
  }
  .pricing-list-area .col-lg-9 {
    width: 90%;
  }
}
/* Medium Layout(iPad Pro): 1024px. */
@media only screen and (max-width: 1050px) {
  html {
    overflow-x: hidden;
  }
  .hero-image {
    width: 400px;
  }
  .hero-area {
    padding: 20px 0;
  }
  .client-image {
    height: 80px;
  }
  .service-icon {
    height: 50px;
  }
  .homepage-contact-us-shape {
    width: 50%;
  }
  .blog-container {
    padding: 20px;
  }

  .blog-title {
    font-size: 1.8rem;
    line-height: 1.4;
  }

  .toc-container {
    position: static;
    margin-bottom: 24px;
  }
}
/* Tablet Layout: 768px. */
@media only screen and (max-width: 991px) {
  .mobile-column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .d-xs-none {
    display: none !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .header-logo a span img {
    object-position: center !important;
  }
  .testimonial-person-image {
    height: 110px;
    width: 110px;
  }
  .blog-list-slider-area .swiper-button-prev {
    left: -20px;
    right: auto;
  }
  .blog-list-slider-area .swiper-button-next {
    right: -20px;
    left: auto;
  }
  .homepage-contact-us__info {
    text-align: center;
    margin-top: 40px;
  }
  .homepage-contact-phone-number {
    margin-top: 10px !important;
  }
  .homepage-contact-us__title {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
  }
  .footer-shape {
    width: 102%;
  }
  .footer-description {
    padding-right: 40%;
  }
  .subscription-area {
    margin-top: 30px;
  }

  .fotter-social-links {
    margin-top: 20px;
  }
  .best-features-image {
    height: 300px;
    margin-bottom: 40px;
  }
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .app-details-area {
    margin-top: 0 !important;
  }
  .pricing-list-area .col-lg-9 {
    width: 100%;
  }
  .popular-product-list-area .nav .nav-item {
    width: 33.33333%;
  }
  .product-details-box > .row {
    flex-direction: column-reverse;
  }
  .product-details-box {
    padding: 40px !important;
  }

  .product-details-content {
    padding-bottom: 0 !important;
  }
  .product-details-image {
    height: 260px;
    margin-bottom: 20px;
  }
  .page-sidebar {
    margin-top: 40px;
  }

  .contact-info-form-area {
    padding: 40px 0;
  }
  .contact-info-list {
    margin-bottom: 50px;
  }
  .contact-info-box .icon {
    width: 60px;
    height: 60px;
    padding: 21px;
  }
  .tab-list-slider-area .swiper-button-prev,
  .tab-list-slider-area .swiper-rtl .swiper-button-next {
    left: -10px;
    right: auto;
  }

  .tab-list-slider-area .swiper-button-next,
  .tab-list-slider-area .swiper-rtl .swiper-button-prev {
    right: -10px;
    left: auto;
  }
  .single-career-image {
    height: 360px;
  }
  .share-area {
    display: none !important;
  }
  .blog-mobile-share-area {
    display: block;
  }
  .blog-mobile-share-area h4 {
    font-size: 16px;
  }
  .comment-user {
    flex-wrap: wrap;
  }
  .comment-reply {
    flex-wrap: wrap;
  }
  .clients-title-area .clients-title {
  font-size: 42px;
  text-transform: uppercase;
}
.clients-title-area .clients-subtitle {
  font-size: 14px;
  position: relative;
}
.clients-title-area .clients-subtitle::before {
  display: none;
}
  .blog-container {
    padding: 18px;
  }

  .blog-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .blog-author {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .blog-share {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .blog-image {
    margin: -16px;
    width: calc(100% + 32px);
    border-radius: 0;
    margin-bottom: 16px;
  }

  .blog-content {
    font-size: 1rem;
    line-height: 1.6;

    h2 {
      font-size: 1.4rem;
      line-height: 1.3;
      margin: 24px 0 16px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.3;
      margin: 20px 0 12px;
    }

    p {
      margin-bottom: 16px;
    }
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .toc-container {
    display: none;
  }

  .col-md-9 {
    width: 100%;
    padding: 0;
  }
}
/* Mobile Layout: 375px. */
@media only screen and (max-width: 767px) {
  html,
  body {
    overflow-x: hidden;
  }
  .d-xsm-none {
    display: none !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .header-right-area a .icon {
    margin-left: 0 !important;
  }
  .hero-title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 15px !important;
  }
  .hero-link a,
  .app-details-link a,
  .product-details-link a {
    font-size: 14px;
  }
  .hero-content,
  .testimonial-area {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .section-title-area {
    margin-bottom: 10px !important;
  }
  .testimonial-icon span img {
    object-position: center !important;
  }
  .testimonial-person-image {
    height: 80px;
    width: 80px;
  }
  .testimonial-area .row.mt-4 {
    margin-top: 10px !important;
  }
  .blog-title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 12px;
    word-wrap: break-word;
    padding-bottom: 10px;
    border-bottom: 2px double #7f7f7f55;
  }

  .service-title {
    font-size: 15px;
    line-height: 22px;
  }

  .service-box-inner p,
  .blog-content-inner p {
    font-size: 13px;
    line-height: 20px;
  }
  .service-more-link a,
  .blog-reading-time {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }
  .feature-box-area p,
  .homepage-contact-us__form button {
    font-size: 13px;
    line-height: 23px;
  }
  .section-title-area h3 {
    line-height: 32px;
    margin-bottom: 8px !important;
  }
  .blog-image {
    height: 240px;
  }
  .footer-description {
    padding-right: 0;
  }
  .app-details-content {
    padding-top: 0 !important;
  }
  .app-details-image {
    width: 40%;
    height: 40%;
  }
  .app-details-box {
    padding-bottom: 10px;
  }
  .cta-action {
    display: flex;
    margin-top: 30px;
  }
  .popular-product-list-area .nav .nav-item {
    width: 50%;
  }
  .goal-box-area {
    padding: 15px !important;
  }

  .faq-area .section-title-area {
    margin-bottom: 1.3rem !important;
  }

  .contact-info-box .icon {
    width: 50px;
    height: 50px;
    padding: 17px;
  }
  .contact-info-list .contact-info-box:not(:last-child) {
    margin-bottom: 20px;
  }
  .contact-info-list {
    margin-bottom: 30px;
  }
  .contact-info-form-area {
    padding: 20px 0;
  }
  main.main {
    padding-top: 56px;
  }
  .header-menu-fixed-area .header-logo {
    height: 100px;
  }
  .case-image {
    height: 200px;
  }
  .single-career-image {
    height: 260px;
  }
  .description-box-inner ul {
    margin-left: 10px;
  }
  .description-box-inner h4 {
    font-size: 15px;
  }
  .job-apply-link {
    margin-top: 30px !important;
  }
  .page-sidebar.page-sidebar-sticky {
    margin-top: 0;
  }
  .page-details-area.common-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .modules-list-area {
    grid-template-columns: auto;
  }
  .module-box-area-new {
    min-height: max-content;
  }
  .delivery-service-box-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    height: 150px;
  }
  .delivery-box-area {
    flex-direction: column-reverse;
  }
  .delivery-box-area:nth-child(even) {
    flex-direction: column-reverse;
  }
  .delivery-service-box-info-title h2 {
    font-size: 20px;
  }
  .delivery-service-box-info-title p {
    font-size: 15px;
    line-height: 20px;
  }
  .delivery-service-box-info-item li {
    font-size: 15px;
    margin-top: 15px;
  }
  .delivery-service-box-info-item li p {
    font-size: 12px;
  }
  .delivery-service-box-image span img {
    object-position: center center !important;
  }
  .delivery-box-area:nth-child(even) .delivery-service-box-image span img {
    object-position: center center !important;
  }
  .app-details-image {
    margin-bottom: 15px;
    width: 100%;
    height: 150px;
  }
  .app-details-box {
    flex-direction: column-reverse;
    border-radius: 0px !important;
  }
  .app-details-title {
    font-size: 20px;
  }
  .app-details-description {
    font-size: 14px;
  }
  .delivery-service-box-info-item li::before,
  .delivery-box-area:nth-child(even) .delivery-service-box-info-item li::after {
    width: 10px;
    height: 10px;
    top: 10px;
  }
  .clients-title-area .clients-title {
  font-size: 32px;
  text-transform: uppercase;
}
.clients-title-area .clients-subtitle {
  font-size: 13px;
  position: relative;
}
  .blog-container {
    padding: 16px;
    overflow-x: hidden;
  }

  .blog-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .blog-author {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .blog-share {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .blog-image {
    margin: -16px;
    width: calc(100% + 32px);
    border-radius: 0;
    margin-bottom: 16px;
  }

  .blog-content {
    font-size: 1rem;
    line-height: 1.6;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;

    img {
      max-width: 100%;
      height: auto;
    }

    h2 {
      font-size: 1.4rem;
      line-height: 1.3;
      margin: 24px 0 16px;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.3;
      margin: 20px 0 12px;
    }

    p {
      margin-bottom: 16px;
    }
  }

  .container {
    padding: 0;
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
  }

  .toc-container {
    display: none;
  }

  .col-md-9 {
    padding: 0;
  }

  .py-5 {
    padding: 1rem 0 !important;
  }

  .blog-wrapper {
    overflow-x: hidden;
  }

  .blog-container {
    padding: 16px;
    overflow-x: hidden;
  }

  .blog-title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 12px;
    word-wrap: break-word;
  }

  .blog-content {
    font-size: 1rem;
    line-height: 1.6;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
/* Wide Mobile Layout(Nexus 7): 600px. */
@media only screen and (max-width: 610px) {
}
@media only screen and (max-width: 990px) {
  .app-details-box {
    flex-direction: column-reverse;
    border-radius: 0px !important;
  }
  .app-details-image {
    margin-bottom: 10px;
    width: 100%;
    height: 200px;
  }
  .hero-image {
    width: 100% !important;
    height: 210px !important;
  }
  .hero-image img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    object-position: center;
  }
}
/* Wide Mobile Layout: 480px. */
@media only screen and (max-width: 500px) {
  .d-xss-none {
    display: none !important;
  }
  .d-xss-block {
    display: block !important;
  }
  .d-xss-flex {
    display: flex !important;
  }
  .header-area {
    padding: 10px 0 !important;
  }
  .header-menu-button svg {
    height: 20px;
  }
  .hero-title {
    font-size: 26px;
    line-height: 38px;
    margin-bottom: 10px !important;
  }
  .hero-description {
    font-size: 16px;
    line-height: 25px;
    padding-right: 0 !important;
  }
  .hero-link a,
  .app-details-link a,
  .product-details-link a,
  .pricing-action-area a,
  .cta-action a {
    font-size: 12px;
    padding: 10px 15px !important;
  }
  .hero-link,
  .app-details-link,
  .product-details-link {
    margin-top: 10px !important;
  }
  .hero-content,
  .testimonial-area,
  .services-area,
  .features-area,
  .blog-area,
  .homepage-contact-us-area {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .section-title-area h3,
  .homepage-contact-us__title h3 {
    line-height: 20px;
    margin-bottom: 4px !important;
    font-size: 15px;
  }
  .section-title-area h2,
  .homepage-contact-us__title h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .services-list-area .row .mt-4,
  .features-list-area .row .mt-4 {
    margin-top: 15px !important;
  }
  .section-title-area {
    margin-bottom: 0px !important;
  }
  .clients-area:before,
  .clients-area:after {
    display: none;
  }
  .feature-icon {
    height: 50px;
  }
  .feature-title {
    font-size: 22px;
    margin-bottom: 10px !important;
    line-height: 30px;
  }
  .blog-image {
    height: 200px;
  }
  .blog-content {
    // padding: 25px !important;
  }
  .homepage-contact-us-area-wrapper {
    padding: 15px !important;
    padding-top: 20px !important;
  }
  .homepage-contact-us-shape {
    width: 90%;
  }
  .homepage-contact-us__form button {
    font-size: 17px;
  }
  .btn-submit .icon svg {
    height: 20px;
  }
  .homepage-contact-us__info h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .homepage-contact-us__info {
    margin-top: 30px;
  }
  .homepage-contact-phone-number {
    margin-top: 0px !important;
  }
  .homepage-contact-phone-number .phone-number,
  .homepage-contact-phone-number a {
    padding: 10px 15px !important;
  }
  .homepage-contact-phone-number {
    width: 100%;
    justify-content: space-between;
  }
  .footer {
    margin-top: 0;
    padding-bottom: 20px !important;
  }
  .footer-logo {
    margin-top: 0 !important;
  }
  .footer-description {
    margin-top: 30px !important;
  }
  .subscription-area {
    margin-top: 20px;
  }
  .footer-links-area .row.mt-5 {
    margin-top: 30px !important;
  }
  .footer-links-area .col-4 {
    width: 50%;
  }
  .copyright-area {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .best-features-image {
    height: 200px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .title-line-curve img {
    height: 40px;
  }
  .title-line-curve.small img {
    height: 24px;
  }
  .app-details-box {
    padding: 20px;
  }
  .pricing-feature-list ul li .icon {
    width: 20px;
    height: 20px;
  }
  .pricing-box-area {
    padding-bottom: 25px !important;
  }
  .cta-box-area {
    padding: 25px !important;
  }
  .popular-product-list-area .nav .nav-item {
    width: 80%;
  }
  .product-details-image {
    height: 170px;
    margin-bottom: 10px;
  }
  .product-details-box {
    padding: 30px !important;
  }
  .popular-product-list-area .nav {
    scrollbar-width: none;
  }
  .popular-product-list-area .nav::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .page-breadcumb-area {
    padding: 30px 0 20px;
  }
  .page-breadcumb-area h3 {
    font-size: 26px;
    line-height: 42px;
    margin-bottom: 10px;
  }
  .blog-share-area {
    padding: 10px;
    text-align: center !important;
    margin-top: 20px !important;
  }
  .page-sidebar {
    margin-top: 40px;
  }
  .blog-description h1 {
    font-size: 20px;
  }
  .blog-description {
    font-size: 15px;
    line-height: 32px;
  }
  .page-sidebar-box h3 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .blog-share-area h4 {
    font-size: 16px;
  }
  .outline-list ul li {
    margin-bottom: 0 !important;
  }

  .outline-list {
    margin-bottom: 25px;
  }
  // .blog-share-area .row {
  // 	justify-content: center !important;
  // 	align-items: center !important;
  // 	flex-direction: column;
  // }

  .blog-share-area .row .text-end {
    text-align: center !important;
  }

  .blog-share-list ul {
    align-items: center;
  }
  .blog-share-list ul li {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
  }
  h3.comments-title.pt-5 {
    padding-top: 10px !important;
  }
  .blog-share-list ul li svg {
    height: 22px;
  }
  .hero-image {
    width: 100% !important;
    height: 150px !important;
  }
  .hero-image img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    object-position: center;
  }
}
/* Mobile Layout(Samsung Galaxy S5): 360px. */
@media only screen and (max-width: 370px) {
}
/* Mobile Layout(iPhone 4): 320px. */
@media only screen and (max-width: 330px) {
}

/* Small Mobile Layout: 320px */
@media only screen and (max-width: 375px) {
  .blog-container {
    padding: 12px;
  }

  .blog-title {
    font-size: 1.3rem;
  }

  .blog-content {
    font-size: 0.95rem;

    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1.1rem;
    }
  }

  .blog-image {
    margin: -12px;
    width: calc(100% + 24px);
  }
}

/* Large Desktop */
@media only screen and (min-width: 1400px) {
  .blog-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .blog-title {
    font-size: 4rem;
  }

  .blog-content {
    font-size: 1.1rem;
    line-height: 1.7;
  }

  .toc-container {
    position: sticky;
    top: 80px;
  }
}
