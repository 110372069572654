$blue: #005bd4;
$sky-blue: #005bd4;
$sky-blue-dark: #1e54fa;
$light-blue: #50c8fc;
$white-dark-blue: #f2faff;
$gray-purple: #4e4b66;
$purple-light: #6e7191;
$purple-dark: #262338;
$white-blue: #f7f7fc;
$white-cream: #fcfcfc;
$white-gray: #eff0f6;
$dark-blue: #14142b;
$gray-muted: #bdbdbd;

$font-sizes: 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 28, 30, 32, 40, 48,
  56, 64;
$line-heights: 16, 18, 20, 21, 22, 24, 27, 28, 29, 30, 32, 35, 36, 40, 44, 48,
  50, 56, 60, 62, 88;
$letter-spacing: 16, 20;
$border-radius: 8, 12, 16, 24, 30, 32;
$width-percentage: 20, 30, 65, 70, 80, 90;
$height-percentage: 20, 30, 70, 80, 90;
$gutters: 20, 30, 50;
$z-index: 1, 2, 3;

$text-colors: ('blue', $blue), ('gray-purple', $gray-purple),
  ('sky-blue', $sky-blue), ('light-blue', $light-blue),
  ('purple-light', $purple-light), ('dark-blue', $dark-blue);

$background-colors: ('white-blue', $white-blue), ('purple-light', $purple-light),
  ('white-cream', $white-cream), ('white-gray', $white-gray),
  ('sky-blue-dark', $sky-blue-dark), ('gray-muted', $gray-muted),
  ('dark-blue', $dark-blue);

$border-colors: ('blue', $blue), ('purple-light', $purple-light),
  ('white-gray', $white-gray);

// $gradient: linear-gradient(to right, #0457c2, #b2cff5);
$gradient: linear-gradient(to left, #d9d9f0, #8fb8f1,$blue);
$gradient2: linear-gradient(to right, #067b9e, #43dbdb);
$gradientss: linear-gradient(to right, #0552fa, #43dbdb);
$gradient-hover: linear-gradient(to left, #067b9e, #43dbdb);
