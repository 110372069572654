@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");

@import "variables";

a,
a:hover,
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: 0 solid !important;
  text-decoration: none;
}
a {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: $gray-purple;
}
button {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: transparent;
  border: none;
}
a:hover {
  color: #000;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: 700;
}

*:disabled {
  cursor: not-allowed;
}
/* html {
	overflow-x: hidden;
} */

body {
  // overflow-x: hidden;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  background-color: #fff;
  color: $gray-purple;
  font-family: "Poppins", sans-serif;
}
* {
  scrollbar-width: thin;
  scrollbar-color: $gray-purple #f1f1f1;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: $gray-purple;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $gray-purple;
}

::-moz-selection {
  background: #1d1d1d;
  color: #fff;
  -webkit-text-fill-color: #fff;
}
::selection {
  background: #1d1d1d;
  color: #fff;
  -webkit-text-fill-color: #fff;
}

.common-padding {
  padding-top: 50px;
  padding-bottom: 50px;
}
.common-padding-top {
  padding-top: 50px;
}
.common-padding-bottom {
  padding-bottom: 50px;
}

.ff-poppins {
  font-family: "Poppins", sans-serif;
}
.ff-roboto {
  font-family: "Roboto", sans-serif;
}

.transition-all-3 {
  transition: 0.3s;
}

.fw-light {
  font-weight: 300 !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: 800 !important;
}

@each $gutter in $gutters {
  .gx-#{$gutter} {
    margin-left: #{$gutter * -1}px;
    margin-right: #{$gutter * -1}px;
  }

  .gx-#{$gutter} > [class^="col-"] {
    padding-left: #{$gutter}px;
    padding-right: #{$gutter}px;
  }
}

@each $width in $width-percentage {
  .w-#{$width} {
    width: $width * 1%;
  }
}
@each $height in $height-percentage {
  .h-#{$height} {
    height: $height * 1%;
  }
}
@each $index in $z-index {
  .zi-#{$index} {
    z-index: $index;
  }
}

@each $size in $font-sizes {
  .fs-#{$size} {
    font-size: #{$size * (30/40)}px;
  }
}

@each $height in $line-heights {
  .lh-#{$height} {
    line-height: #{$height * (30/40)}px;
  }
}

@each $spacing in $letter-spacing {
  .ls-#{$spacing} {
    letter-spacing: calc(calc(($spacing * 15) / 1000) * 1px);
  }
}

@each $radius in $border-radius {
  .radius-#{$radius} {
    border-radius: #{$radius * (30/40)}px !important;
  }
  .radius-top-left-#{$radius} {
    border-top-left-radius: #{$radius * (30/40)}px !important;
  }
  .radius-top-right-#{$radius} {
    border-top-right-radius: #{$radius * (30/40)}px !important;
  }
  .radius-bottom-left-#{$radius} {
    border-bottom-left-radius: #{$radius * (30/40)}px !important;
  }
  .radius-bottom-right-#{$radius} {
    border-bottom-right-radius: #{$radius * (30/40)}px !important;
  }
}

@each $color in $text-colors {
  .text-#{nth($color, 1)} {
    color: #{nth($color, 2)};
  }
}

@each $color in $text-colors {
  .active .text-hover-#{nth($color, 1)},
  .text-hover-#{nth($color, 1)}:hover {
    color: #{nth($color, 2)} !important;
  }
}
@each $color in $background-colors {
  .bg-#{nth($color, 1)} {
    background-color: #{nth($color, 2)};
  }
}
@each $color in $border-colors {
  .border-#{nth($color, 1)} {
    border: 1px solid #{nth($color, 2)};
  }
}
.case-box-area {
  background-color: #0382fc;
}
.bg-gradient-blue-light-blue {
  background: #063ef9;
  background: -moz-linear-gradient(left, #063ef9 2%, #00baff 97%);
  background: -webkit-linear-gradient(left, #063ef9 2%, #00baff 97%);
  background: linear-gradient(to right, #063ef9 2%, #00baff 97%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#063ef9', endColorstr='#00baff',GradientType=1 );
}
.bg-gradient-blue-light-blue.reverse {
  background: #00baff;
  background: -moz-linear-gradient(left, #00baff 2%, #063ef9 97%);
  background: -webkit-linear-gradient(left, #00baff 2%, #063ef9 97%);
  background: linear-gradient(to right, #00baff 2%, #063ef9 97%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00baff', endColorstr='#063ef9',GradientType=1 );
}

.bg-gradient-blue-light-blue.vertical {
  background: -moz-linear-gradient(
    top,
    rgba(6, 62, 249, 0.8) 0%,
    rgba(0, 186, 255, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(6, 62, 249, 0.8) 0%,
    rgba(0, 186, 255, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(6, 62, 249, 0.8) 0%,
    rgba(0, 186, 255, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc063ef9', endColorstr='#cc00baff',GradientType=0 );
}

.bg-gradient-pink {
  background: #fba5fb;
  background: -moz-linear-gradient(left, #fba5fb 0%, #cd24cd 100%);
  background: -webkit-linear-gradient(left, #fba5fb 0%, #cd24cd 100%);
  background: linear-gradient(to right, #fba5fb 0%, #cd24cd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fba5fb', endColorstr='#cd24cd',GradientType=1 );
}
.bg-gradient-yellow {
  background: #f9cb40;
  background: -moz-linear-gradient(left, #f9cb40 0%, #ff8227 100%);
  background: -webkit-linear-gradient(left, #f9cb40 0%, #ff8227 100%);
  background: linear-gradient(to right, #f9cb40 0%, #ff8227 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9cb40', endColorstr='#ff8227',GradientType=1 );
}
.bg-gradient-green {
  background: #23f4cf;
  background: -moz-linear-gradient(left, #23f4cf 0%, #08b8ac 100%);
  background: -webkit-linear-gradient(left, #23f4cf 0%, #08b8ac 100%);
  background: linear-gradient(to right, #23f4cf 0%, #08b8ac 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23f4cf', endColorstr='#08b8ac',GradientType=1 );
}
.bg-gradient-red-dark-light {
  background: #c31432;
  background: -webkit-linear-gradient(to right, #240b36, #c31432);
  background: linear-gradient(to right, #240b36, #c31432);
}
.bg-gradient-green-purple-megenta {
  background: #12c2e9;
  background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
  background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
}
.bg-gradient-green-lime {
  background: #11998e;
  background: -webkit-linear-gradient(to left, #38ef7d, #11998e);
  background: linear-gradient(to left, #38ef7d, #11998e);
}
.bg-gradient-blue-hydrozen {
  background: #667db6;
  background: -webkit-linear-gradient(
    to left,
    #667db6,
    #0082c8,
    #0082c8,
    #667db6
  );
  background: linear-gradient(to left, #667db6, #0082c8, #0082c8, #667db6);
}
.bg-gradient-blue-lagoon {
  background: #43c6ac;
  background: -webkit-linear-gradient(to right, #191654, #43c6ac);
  background: linear-gradient(to right, #191654, #43c6ac);
}
.bg-gradient-ocean-lemon {
  background: #00c3ff;
  background: -webkit-linear-gradient(to left, #ffff1c, #00c3ff);
  background: linear-gradient(to left, #ffff1c, #00c3ff);
}
.bg-gradient-sky-sunset {
  background: #0b486b;
  background: -webkit-linear-gradient(to right, #f56217, #0b486b);
  background: linear-gradient(to right, #f56217, #0b486b);
}
.bg-gradient-dragon-ash {
  background: #16222a;
  background: -webkit-linear-gradient(to left, #3a6073, #16222a);
  background: linear-gradient(to left, #3a6073, #16222a);
}
.bg-gradient-north-sunset {
  background: #1a2a6c;
  background: -webkit-linear-gradient(to right, #fdbb2d, #b21f1f, #1a2a6c);
  background: linear-gradient(to right, #fdbb2d, #b21f1f, #1a2a6c);
}
.bg-gradient-sound-cloud {
  background: #fe8c00;
  background: -webkit-linear-gradient(to right, #f83600, #fe8c00);
  background: linear-gradient(to right, #f83600, #fe8c00);
}
.bg-gradient-chocolate-coffie {
  background: #1e130c;
  background: -webkit-linear-gradient(to left, #9a8478, #1e130c);
  background: linear-gradient(to left, #9a8478, #1e130c);
}

.text-gradient-blue-light-blue,
.accordion-button:not(.collapsed) .text {
  background: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
}
.text-gradient-blue-light-blue.reverse,
.text-gradient-blue-light-blue.hover:hover,
.hover:hover .text-gradient-blue-light-blue {
  background: linear-gradient(95.08deg, #2fece6 2.49%, #0f5ef9 97.19%);
}

.text-gradient-pink {
  background: linear-gradient(95.08deg, #fba5fb 2.49%, #cd24cd 97.19%);
}
.text-gradient-yellow {
  background: linear-gradient(95.08deg, #f9cb40 2.49%, #ff8227 97.19%);
}
.text-gradient-green {
  background: linear-gradient(316.82deg, #08b8ac 13.55%, #23f4cf 95.97%);
}

.text-gradient,
.accordion-button:not(.collapsed) .text {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.btn-gradient {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  z-index: 1;
}
.btn-gradient:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-out;
  z-index: 2;
  opacity: 0;
  border-radius: 9px !important;
}
.btn-gradient.hover:hover:after {
  opacity: 1;
}
.btn-gradient span {
  position: relative;
  z-index: 3;
}

.btn-gradient-blue-light-blue {
  background-color: #0f5ef9;
  background-image: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
}
.btn-gradient-blue-light-blue:after {
  background-image: linear-gradient(95.08deg, #2fece6 2.49%, #0f5ef9 97.19%);
}

.btn-gradient-black-silver {
  background-color: #000000;
  background-image: linear-gradient(95.08deg, #000000 2.49%, #434343 97.19%);
}
.btn-gradient-black-silver:after {
  background-image: linear-gradient(95.08deg, #434343 2.49%, #000000 97.19%);
}

.btn-gradient-blue-light-blue.reverse {
  background-color: #2fece6;
  background-image: linear-gradient(95.08deg, #2fece6 2.49%, #0f5ef9 97.19%);
}
.btn-gradient-blue-light-blue.reverse:after {
  background-image: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
}

.border-gradient {
  border: 2px solid;
  border-image-slice: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.border-gradient-rounded {
  border: none;
  z-index: 1;
  * {
    z-index: 3;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    border-radius: inherit;
    transition: opacity 0.3s ease-out;
  }
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    z-index: 1;
    border-radius: inherit;
    background: inherit;
    transition: opacity 0.3s ease-out;
  }
}

.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.border-gradient-rounded.border-gradient-blue-light-blue {
  border-image-source: none;
  &:before {
    background: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
  }
  &:hover:before {
    background: linear-gradient(95.08deg, #2fece6 2.49%, #0f5ef9 97.19%);
  }
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next,
.swiper-button-prev {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 9px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-top: 0;
  outline: 0 solid;
  background: #fff;
  color: #1d1d1d;
  font-size: 12px;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  transform: translateY(-50%);
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

/* HEADER CSS */
.header-area {
  z-index: 1000;
}
.header-logo,
.footer-logo {
  height: 40px;
}

.header-menu-area > ul > li > ul {
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 5px #ddd;
}

.header-menu-area > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
}

.header-menu-area > ul > li > a:before {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 3px;
  background: transparent;
  content: "";
  transition: 0.4s;
}

.header-menu-area > ul > li > a.active:before,
.header-menu-area > ul > li:hover > a:before {
  width: 100%;
  background: #fff;
}

.header-menu-area ul li a.py-4 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
main.main {
  padding-top: 56px;
}

/* HERO AREA CSS */
.hero-area {
  padding: 40px 0;
}

.hero-shape {
  width: 42%;
}

.hero-image {
  width: 500px;
}
.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.hero-description {
  text-align: justify;
}

.image-scaleup {
  position: absolute;
  animation: scaleup 5s infinite linear;
}
@keyframes scaleup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scalex(1.2);
  }
  100% {
    transform: rotate(1.4);
  }
}

.hero-blush {
  background: linear-gradient(
    95.08deg,
    #faceb8 2.49%,
    #e6afcc 33.56%,
    #c8bee5 64.63%,
    #acd1f1 97.19%
  );
  opacity: 0.4;
  filter: blur(280px);
  width: 1000px;
}
// clients-area
.clients-area-full {
  padding: 40px 0;
  // background-image: $gradientss;
  background-color: rgb(250, 247, 247);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.clients-title-area {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(211, 209, 209);
}
.clients-title-area .clients-title {
  font-size: 52px;
  text-transform: uppercase;
}
.clients-title-area .clients-subtitle {
  font-size: 16px;
  position: relative;
}
.clients-title-area .clients-subtitle::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 90%;
  background-color: rgb(201, 197, 197);
  top: 3px;
  left: -18px;
}
// .clients-area:before,
// .clients-area:after {
//   position: absolute;
//   width: 40px;
//   height: 100%;
//   content: '';
//   z-index: 2;
//   top: 0;

// }
// .clients-area:before {
//   left: 0;
//   background: -moz-linear-gradient(
//     left,
//     rgba(246, 246, 251, 1) 0%,
//     rgba(246, 246, 251, 1) 50%,
//     rgba(246, 246, 251, 0.96) 52%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   background: -webkit-linear-gradient(
//     left,
//     rgba(246, 246, 251, 1) 0%,
//     rgba(246, 246, 251, 1) 50%,
//     rgba(246, 246, 251, 0.96) 52%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   background: linear-gradient(
//     to right,
//     rgba(246, 246, 251, 1) 0%,
//     rgba(246, 246, 251, 1) 50%,
//     rgba(246, 246, 251, 0.96) 52%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6fb', endColorstr='#00ffffff',GradientType=1 );
// }
// .clients-area:after {
//   right: 0;
//   background: -moz-linear-gradient(
//     left,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(246, 246, 251, 0.96) 48%,
//     rgba(246, 246, 251, 1) 50%,
//     rgba(246, 246, 251, 1) 100%
//   );
//   background: -webkit-linear-gradient(
//     left,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(246, 246, 251, 0.96) 48%,
//     rgba(246, 246, 251, 1) 50%,
//     rgba(246, 246, 251, 1) 100%
//   );
//   background: linear-gradient(
//     to right,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(246, 246, 251, 0.96) 48%,
//     rgba(246, 246, 251, 1) 50%,
//     rgba(246, 246, 251, 1) 100%
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f6f6fb',GradientType=1 );
// }
.client-image {
  height: 100px;
}

.module-box-area,
.feature-box-area,
.blog-box-area,
.service-box-area,
.goal-box-area,
.job-box-area {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 11%);
}
.swiper-slide .blog-box-area {
  box-shadow: 0 0 0 0;
}

.service-box-inner-tech {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sevice-icon-image {
  object-fit: contain;
  object-position: center;
}
.service-icon {
  height: 70px;
}
.service-icon-tech {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-blush-1,
.service-blush-2 {
  width: 300px;
  height: 300px;
}
.service-blush-1 {
  background: linear-gradient(95.08deg, #af45db 2.49%, #eb7b96 97.19%);
  opacity: 0.36;
  filter: blur(320px);
  left: -80px;
  top: -50px;
}
.service-blush-2 {
  background: linear-gradient(95.08deg, #a26bfa 2.49%, #4f06c6 97.19%);
  opacity: 0.36;
  filter: blur(320px);
  right: -90px;
  bottom: -60px;
}

.testimonial-box-area {
  box-shadow: 0px 4px 8px rgba(96, 96, 96, 0.12),
    2px 2px 32px rgba(0, 0, 0, 0.04);
}

.testimonial-icon {
  height: 20px;
}

.testimonial-person-image {
  height: 150px;
  width: 150px;
}

.testimonial-blush-1 {
  background: linear-gradient(95.08deg, #edf7f8 2.49%, #b5c7e1 97.19%);
  opacity: 0.6;
  filter: blur(280px);
  width: 70%;
  height: 90%;
}
.testimonial-blush-2 {
  background: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
  opacity: 0.28;
  filter: blur(320px);
  width: 300px;
  height: 300px;
}

.testimonial-list-slider .swiper-wrapper {
  padding-bottom: 13px;
}

.testimonial-list-slider-area .swiper-pagination {
  position: relative;
}

.testimonial-list-slider-area .swiper-pagination-bullet-active {
  background: #063ef9;
  background: -moz-linear-gradient(left, #063ef9 2%, #00baff 97%);
  background: -webkit-linear-gradient(left, #063ef9 2%, #00baff 97%);
  background: linear-gradient(to right, #063ef9 2%, #00baff 97%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#063ef9', endColorstr='#00baff',GradientType=1 );
}

.feature-blush {
  background: linear-gradient(95.08deg, #fec790 2.49%, #f991f7 97.19%);
  opacity: 0.2;
  filter: blur(280px);
  width: 90%;
  height: 70%;
}

.feature-icon {
  height: 60px;
}

.blog-list-slider-area {
  margin: -10px;
}

.blog-list-slider-area .swiper {
  padding: 10px;
}

.blog-image {
  height: 180px;
  background-color: #eee;
}

.blog-list-slider-area .swiper-button-prev {
  left: -50px;
  right: auto;
}

.blog-list-slider-area .swiper-button-next {
  right: -50px;
  left: auto;
}

.homepage-contact-us__form input,
.homepage-contact-us__form textarea {
  background: #fcfcfc;
  border: 1px solid #fcfcfc;
  width: 100%;
  border-radius: 10px;
  padding: 14px 20px;
  padding-left: 50px;
}
.homepage-contact-us__form input:focus,
.homepage-contact-us__form textarea:focus {
  border-color: #14142b;
}
.homepage-contact-us__form .form-group {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}

.homepage-contact-us__form .form-group label {
  position: absolute;
  left: 22px;
  top: 15px;
  color: #6e7191;
}

.homepage-contact-us-shape {
  left: 20px;
}

.fotter-social-links a {
  background: rgb(255 255 255 / 10%);
  padding: 14px;
  width: 40px;
  height: 40px;
}
.fotter-social-links a:hover {
  background: rgb(255 255 255 / 15%);
}

.subscription-form {
  border: 2px solid #fff;
}

.subscription-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
.subscription-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.subscription-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}
.subscription-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
.footer-links a {
  opacity: 0.7;
}

.footer-links a:hover {
  opacity: 1;
}

.footer-shape {
  height: calc(100% + 70px);
  width: 51%;
  left: -100px !important;
}
.footer-shape img {
  width: 100%;
  height: 100%;
}
.footer {
  margin-top: 80px;
}

.footer-description {
  padding-right: 65px;
  letter-spacing: 0.75px;
}

.scroll-to-top {
  width: 40px;
  height: 40px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  border-top-left-radius: 10px !important;
  overflow: hidden;
}
.scroll-to-top.active {
  opacity: 1;
  visibility: visible;
}
.scroll-to-top:after {
  border-radius: 0 !important;
}

.footer-logo:before {
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 300px;
  height: 2px;
  background: #fff;
  content: "";
}

.header-menu-area a .icon svg,
.btn-submit .icon svg,
.product-details-link .icon svg,
.app-details-link .icon svg,
.hero-link .icon svg {
  height: 14px;
}
.service-more-link .icon svg {
  height: 10px;
}
.header-right-area a .icon svg {
  height: 15px;
}

.menu-backdrop {
  background: rgb(0 0 0 / 50%);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.menu-backdrop.active {
  opacity: 1;
  visibility: visible;
}

.header-menu-fixed-area {
  left: -300px;
  top: 0;
  width: 300px;
  height: 100%;
  background: #fff;
  z-index: 1001;
  padding-top: 40px;
  transition: 0.3s;
}

.header-menu-fixed-area.active {
  left: 0;
}

.header-menu-fixed-area .header-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  background: rgb(0 0 0 / 15%);
}

.header-menu-fixed-area .header-menu-area ul {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.header-menu-fixed-area .header-menu-area ul li {
  margin: 5px 0 !important;
}

.header-menu-fixed-area .header-menu-area ul li a {
  color: #1a1a1a !important;
  padding: 5px !important;
  white-space: normal !important;
}

.header-menu-fixed-area .header-menu-area > ul > li > ul {
  opacity: 1;
  visibility: visible;
  box-shadow: 0 0 0 0;
  position: relative !important;
  margin-left: 20px;
  padding: 0 !important;
}

.header-menu-fixed-area .header-menu-area {
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.fotter-social-links a svg {
  height: 20px;
}
.scroll-to-top .icon svg {
  height: 15px;
}
.homepage-contact-phone-number a .icon svg {
  height: 17px;
}
.homepage-contact-us__form label svg {
  height: 16px;
}
.swiper-buttons svg {
  height: 12px;
}

.header-menu-button svg {
  height: 26px;
}

.header-menu-fixed-area .header-close-button svg {
  height: 15px;
}

.mh-80v {
  min-height: 80vh;
}

.app-details-box {
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.08);
  padding: 40px 50px;
  color: white !important;
}

.app-details-image {
  width: 50%;
  height: 250px;
}

.module-feature-list ul li .icon svg * {
  fill: url(#lgrad);
}

.module-icon {
  width: 70px;
  height: 70px;
}
.module-feature-list ul li {
  margin-left: 35px;
  position: relative;
}
.module-feature-list ul li::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-image: $gradient;
  top: 7px;
  left: -25px;
}
.module-feature-list ul li span {
  line-height: 24px;
}
.module-feature-list ul li .icon svg {
  height: 16px;
}
.pricing-box-area {
  box-shadow: 2px 4px 16px rgba(96, 120, 150, 0.11);
}

.pricing-action-area a .icon svg {
  height: 15px;
}

.pricing-icon {
  width: 60px;
  height: 60px;
}

.pricing-feature-list ul li .icon svg {
  height: 13px;
}

.pricing-feature-list ul li .icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.pricing-feature-list ul li .text {
  width: calc(100% - 30px);
}
.title-line-curve img {
  height: 60px;
}
.title-line-curve.small img {
  height: 35px;
}
.best-features-image {
  height: 400px;
}

.best-features-list-area .accordion-item {
  background-color: transparent;
  border: none;
}
.best-features-list-area .accordion-collapse {
  background: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.best-features-list-area .accordion-button {
  background: transparent;
  box-shadow: 0 0 0 0;
  color: #6e7191;
  font-weight: 500;
  font-size: 21px;
  padding-bottom: 10px;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.best-features-list-area .accordion-button:not(.collapsed) {
  background: #fff;
  box-shadow: 0 0 0 0;
}

.best-features-list-area .accordion-button[aria-expanded="true"] .icon .down,
.best-features-list-area .accordion-button::after,
.best-features-list-area .accordion-button .icon .up {
  display: none;
}

.best-features-list-area .accordion-button[aria-expanded="true"] .icon .up {
  display: block;
}

.best-features-list-area .accordion-button {
  justify-content: space-between;
  border: none;
}
.best-features-list-area .accordion-button:focus {
  box-shadow: 0 0 0 0;
}
.best-features-list-area .accordion-button .icon {
  width: 25px;
  height: 25px;
  overflow: hidden;
}

.best-features-list-area .accordion-body {
  padding-top: 0;
}
.best-features-list ul {
  list-style: disc;
  padding-left: 20px;
}

.best-features-list ul li {
  color: #6e7191;
  margin-top: 6px;
  font-size: 16px;
}
.product-details-image {
  height: 300px;
}

.faq-description {
  background-color: rgb(244, 251, 255);
  text-align: justify;
  border-radius: 8px;
  padding: 1em 0px;
}

.product-faq-area .faq-description p {
  position: relative;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(138, 135, 135);
  text-align: justify;
}

.product-faq-area .faq-description p::before {
  position: absolute;
  content: "A:";
  left: -20px;
  top: 0;
  color: #0082c8;
}
.product-faq-area .best-features-list-area .accordion-button {
  background: transparent;
  box-shadow: 0 0 0 0;
  color: #6e7191;
  font-weight: 500;
  font-size: 17px;
  padding-bottom: 10px;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.product-faq-area .best-features-list-area .accordion-button {
  background: transparent;
  box-shadow: 0 0 0 0;
  color: #6e7191;
  font-weight: 500;
  font-size: 17px;
  padding-bottom: 10px;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.product-faq-area .best-features-list-area .accordion-button .icon {
  max-width: 25px !important;
  max-height: 25px !important;
  padding: 5px !important;
}
.product-faq-area .best-features-list-area .accordion-button .icon .down {
  max-width: 20px !important;
  max-height: 20px !important;
}
.product-faq-area .best-features-list-area .accordion-button .icon .up {
  max-width: 20px !important;
  max-height: 20px !important;
}
// .popular-product-list-area .nav {
// 	overflow: hidden;
// 	overflow-x: auto;
// 	flex-wrap: nowrap;
// }
// .popular-product-list-area .nav .nav-item {
// 	width: 25%;
// 	display: flex;
// 	flex-shrink: 0;
// }
// .product-faq-area {
//   background-color: #e5f3ff;
// }
.popular-product-list-area .nav .nav-item a,
.tab-list-slider-area .single-slider-tab {
  padding: 0.5rem 1rem;
  padding-right: 25%;
  padding-bottom: 15px;
  color: #6e7191;
  position: relative;
  background: rgba(255, 255, 255, 0.04);
  opacity: 0.4;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  cursor: pointer !important;
}

.popular-product-list-area .nav .nav-item a .icon,
.tab-list-slider-area .single-slider-tab .icon {
  width: 50px;
  height: 50px;
}

.popular-product-list-area .nav .nav-item a.active,
.tab-list-slider-area .swiper-slide-thumb-active .single-slider-tab {
  opacity: 1;
  cursor: default !important;
}

.popular-product-list-area .nav .nav-item a:before,
.tab-list-slider-area .single-slider-tab:before {
  position: absolute;
  left: 1rem;
  bottom: 0;
  width: 70%;
  height: 4px;
  background: #f7f7fc;
  content: "";
  border-radius: 20px;
}
.popular-product-list-area .nav .nav-item a.active:before,
.tab-list-slider-area .swiper-slide-thumb-active .single-slider-tab:before {
  background: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
}

.custom-alert-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 70%);
  z-index: 10000;
  border: none;
}
.custom-alert-backdrop:focus {
  outline: 0 solid;
}

div#__react-alert__ > div {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 15000 !important;
  display: block !important;
}

div#__react-alert__ > div > div {
  width: 100% !important;
  height: 100% !important;
}

.custom-alert-box-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 16000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  text-align: center;
}

.custom-alert-box,
.react-confirm-alert-body {
  background: #fff;
  z-index: 18000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  color: #000;
  width: 20em;
  max-width: 100%;
  padding: 1.25em;
  border-radius: 10px;
  box-shadow: 0 0 5px #999;
  text-transform: capitalize;
}

.custom-alert-box .icon {
  width: 50px;
  height: 50px;
  background: linear-gradient(95.08deg, #0f5ef9 2.49%, #2fece6 97.19%);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 23px;
}

.custom-alert-box .icon svg {
  height: 23px;
}

.custom-alert-box .icon.success,
.react-confirm-alert-button-group button:first-child {
  background: #00bfa5;
  color: #fff;
}

.custom-alert-box .icon.error,
.react-confirm-alert-button-group button:last-child {
  background: #f27474;
  color: #fff;
}

.react-confirm-alert-blur {
  -webkit-filter: initial !important;
  filter: initial !important;
  filter: initial !important;
  -webkit-filter: initial !important;
}

.react-confirm-alert-overlay {
  z-index: 9999;
  background: rgb(255 255 255 / 0%);
}
.react-confirm-alert-body > h1 {
  font-size: 14px;
  margin-bottom: 7px;
}

.react-confirm-alert-body {
  font-size: 16px;
}

.page-breadcumb-area {
  background-position: center;
  background-size: cover;
}

.page-breadcumb ul li:not(:last-child) {
  margin-right: 30px;
}

.page-breadcumb ul li:not(:last-child):before {
  position: absolute;
  right: -20px;
  top: 53%;
  transform: translateY(-50%);
  content: ">";
}

.page-breadcumb ul {
  background: #ffffffa6;
  border-radius: 50px;
  padding: 2px 14px;
  font-size: 14px;
}

.page-breadcumb {
  margin-bottom: 20px;
}

.page-breadcumb-area h3 {
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 30px;
}

.page-breadcumb-area {
  padding: 70px 0 50px;
}
.page-sidebar-box {
  margin: 25px 0;
}

.page-sidebar-box h3 {
  font-size: 20px;
  margin-bottom: 25px;
}

.latest-blog-area {
  margin-bottom: 30px;
}
.page-sidebar-box .single-latest-blog .single-latest-blog-image {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border: 1px solid white-gray;
}
.page-sidebar-box .single-latest-blog-title {
  font-size: 14px;
  line-height: 24px;
  width: calc(100% - 100px);
}

a.single-latest-blog:hover {
  color: $blue;
}

.blog-comments-area h3 {
  font-size: 15px;
}

.blog-comments-area {
  margin-top: 40px;
}

.form-control:focus {
  box-shadow: 0 0 0 0;
}

.blog-description {
  font-size: 16px;
  line-height: 38px;
}
.blog-description a {
  color: #2196f3;
}

.equal-height .swiper-slide {
  height: auto;
}

// .blog-share-list ul li svg {
//   height: 30px;
// }

// .blog-share-list ul li a,
// .blog-share-list ul li button {
//   color: #4e4b66;
//   display: inline-flex;
// }
// .blog-share-list ul li a:hover,
// .blog-share-list ul li button:hover {
//   color: $blue;
// }

// .blog-share-list ul {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }

// .blog-share-list ul li {
//   margin-left: 20px;
// }
// share area start
.share-area {
  z-index: 999;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  justify-content: center;
}
.share-area-show-this {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
  transform: translateY(0);
}
.blog-share-area {
  // border: 1px solid #4e4b6678;
  padding: 10px;
  border-radius: 10px;
  width: max-content;
  height: max-content;
}

.blog-share-list ul li svg {
  height: 25px;
}

.blog-share-list ul li a,
.blog-share-list ul li button {
  color: #4e4b66;
}
.blog-share-list ul li a:hover,
.blog-share-list ul li button:hover {
  color: $blue;
}

.blog-share-list ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.blog-share-list ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.blog-share-list ul li:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
// share area end

// .blog-share-area {
//   border: 1px solid #4e4b6678;
//   padding: 40px;
//   border-radius: 30px;
// }

// .blog-share-area h4 {
//   opacity: 0.5;
// }
// blog molile area start
.blog-mobile-share-area {
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  display: none;
}
.blog-mobile-share-area h4 {
  opacity: 0.3;
}
.blog-mobile-share-list ul li svg {
  height: 25px;
}

.blog-mobile-share-list ul li a,
.blog-mobile-share-list ul li button {
  color: #4e4b66;
  display: inline-flex;
}
.blog-mobile-share-list ul li a:hover,
.blog-mobile-share-list ul li button:hover {
  color: $blue;
}

.blog-mobile-share-list ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

// blog molile area end

// author information
.author-details-wrapper {
  padding: 30px 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}
.author-avatar-wrapper {
  width: 150px;
  height: 150px;
}
.author-avatar {
  border: 2px solid rgb(230, 235, 245);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}

//comment form
.comment-form-wrapper {
  margin: 100px 0px 50px 0;
  padding: 30px 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.comment-form-title {
  text-align: center;
}
// comment section
.comment-box-wrapper {
  margin-top: 50px;
}

.comment-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
  margin: 10px 0;
}
.comment-header-icon .comment-icon {
  width: 30px;
  height: 30px;
  color: #00baff;
}

// .comment-box {
//   // padding: 10px 20px;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   border-radius: 10px;
// }
.comment-wrapper {
  padding: 15px 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  gap: 20px;
  position: relative;
  margin-top: 50px;
}
.comment-wrapper:first {
  margin-top: 0;
}

.comment-image-wrapper {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}
.comment-image {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}

.comment-user-info h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}
.comment-name-reply {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-name-reply button {
  padding: 0 15px;
  background-color: #01b7ff;
  color: white;
  border-radius: 15px;
  font-size: 12px;
}
.comment-times {
  display: flex;
  align-items: center;
  gap: 30px;
}
.comment-times span {
  position: relative;
  font-size: 12px;
  font-weight: 500;
}
.comment-times span::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 18px;
  top: 6px;
  left: -15px;
  background-color: rgb(192, 189, 189);
}
.comment-times span:first-child:before {
  display: none;
}
.comment-body p {
  margin: 0;
  font-size: 12px;
}
.comment-reply {
  margin-left: 50px;
  margin-top: -30px !important;
}

.single-comment-user-image {
  width: 60px;
  height: 60px;
}

.single-comment-user-info h3 {
  font-size: 17px;
}

.single-comment-user-info .date {
  font-size: 13px;
  line-height: 17px;
}

.single-comment-user-image {
  margin-right: 12px;
}

.single-comment-description {
  padding: 0 73px;
}

.single-technology-group-title span {
  background: #6e7191;
  color: #fff;
  display: inline-flex;
  padding: 4px 20px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  font-size: 12px;
}

.single-technology-group-title span:before {
  position: absolute;
  left: 50%;
  bottom: -12px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 12px solid #6e7191;
  content: "";
  transform: translateX(-50%);
}

.single-technology-group-title {
  margin-bottom: 12px;
}

.faq-list-area .accordion-item {
  background-color: transparent;
  border: none;
}
.faq-list-area .accordion-collapse {
  background: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.faq-list-area .accordion-button {
  background: transparent;
  box-shadow: 0 0 0 0;
  color: #6e7191;
  font-weight: 500;
  font-size: 21px;
  padding-bottom: 10px;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.faq-list-area .accordion-button:not(.collapsed) {
  background: #fff;
  box-shadow: 0 0 0 0;
}

.faq-list-area .accordion-button[aria-expanded="true"] .icon .down,
.faq-list-area .accordion-button::after,
.faq-list-area .accordion-button .icon .up {
  display: none;
}

.faq-list-area .accordion-button[aria-expanded="true"] .icon .up {
  display: block;
}

.faq-list-area .accordion-button {
  justify-content: space-between;
  border: none;
}
.faq-list-area .accordion-button:focus {
  box-shadow: 0 0 0 0;
}
.faq-list-area .accordion-button .icon {
  width: 25px;
  height: 25px;
  overflow: hidden;
}

.faq-list-area .accordion-body {
  padding-top: 0;
}

.services-list-area .single-technology-group:last-child {
  margin-bottom: 0 !important;
}

.header-menu-area > ul > li > ul li a.active {
  color: $sky-blue;
}

.header-menu-fixed-area .header-menu-area ul li a.active {
  color: $sky-blue !important;
}

.header-menu-area > ul > li > a > .icon svg {
  transition: 0.3s;
}
.header-menu-area > ul > li:hover > a > .icon svg {
  transform: rotate(180deg);
}

.header-menu-area > ul > li > ul li .icon-image {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.header-menu-area > ul > li > ul li .text-info {
  width: calc(100% - 50px);
}

.header-menu-area > ul > li > ul .col-lg-6 {
  width: 320px;
}

.header-menu-area > ul > li > ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.header-menu-area > ul > li > ul > .row {
  flex-wrap: nowrap;
}

.header-menu-fixed-area .header-menu-area > ul > li > ul > .row {
  flex-wrap: wrap;
}

.header-menu-fixed-area .header-menu-area > ul > li > ul .col-lg-6 {
  width: 100%;
}

.header-menu-fixed-area .header-menu-area > ul > li > ul li .icon-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.header-menu-fixed-area .header-menu-area > ul > li > ul li .text-info {
  width: calc(100% - 30px);
}

.header-menu-fixed-area .header-menu-area > ul > li > ul li {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.header-menu-fixed-area
  .header-menu-area
  > ul
  > li
  > ul
  .col-lg-6:last-child
  li {
  border-bottom: none;
}

.contact-info-box .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 24px;
}

.contact-info-box .text {
  width: calc(100% - 90px);
}

.contact-info-form-area {
  padding: 80px 0;
}

.contact-info-list .contact-info-box:not(:last-child) {
  margin-bottom: 40px;
}

.category-list ul li a .icon svg {
  height: 16px;
}

.tab-list-slider-area .swiper-button-prev,
.tab-list-slider-area .swiper-rtl .swiper-button-next {
  left: -40px;
  right: auto;
}

.tab-list-slider-area .swiper-button-next,
.tab-list-slider-area .swiper-rtl .swiper-button-prev {
  right: -40px;
  left: auto;
}

.text-justify {
  text-align: justify;
}

.pagination-row ul li {
  margin: 0 5px;
}

.pagination-row ul li a,
.pagination-row ul li button {
  cursor: pointer;
  padding: 10px 15px;
  display: block;
  border: 1px solid #ddd;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  background: #fff;
  border-radius: 5px;
  height: 36px;
  min-width: 36px;
  border: none;
  box-shadow: 0 0 0 2px transparent;
}

.pagination-row {
  margin-top: 10px !important;
}

.pagination-row ul li a:hover,
.pagination-row ul li button:hover {
  background: #ddd;
}

.pagination-row ul li.active a,
.pagination-row ul li.active button {
  cursor: pointer;
  pointer-events: none;
  background: #063ef9;
  background: -moz-linear-gradient(left, #063ef9 2%, #00baff 97%);
  background: -webkit-linear-gradient(left, #063ef9 2%, #00baff 97%);
  background: linear-gradient(to right, #063ef9 2%, #00baff 97%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#063ef9', endColorstr='#00baff',GradientType=1 );
  box-shadow: 0 0 0 0;
  color: #fff;
  font-weight: 700;
}

.blog-more-link .icon svg {
  width: 12px;
}

.blog-box-area .blog-content-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  max-height: 180px;
}

.blog-image-inner {
  transition: 1.5s;
}

.blog-image:hover .blog-image-inner {
  transform: rotate(5deg) scale(1.1);
}

.single-case-study-image {
  height: 360px;
}

.single-case-study-image-wrapper {
  box-shadow: 0 2px 5px #ddd;
}

.goal-box-inner ul li .icon {
  width: 14px;
}

.case-image {
  height: 250px;
}

.case-more-link.text-white .icon {
  width: 18px;
}

.single-career-image {
  height: 500px;
}

.description-box-inner ul {
  list-style: disc;
  padding-left: 20px;
  margin-left: 30px;
}

.description-box-inner p {
  margin-bottom: 30px;
}

.description-box-inner h4 {
  font-size: 20px;
  text-decoration: underline;
}

.form-error-message {
  color: #f00;
  font-size: 13px;
}

.blog-description ul {
  list-style: disc;
  padding-left: 20px;
  margin-left: 40px;
  margin-bottom: 40px;
}

.blog-description h1 {
  font-size: 26px;
}

.page-sidebar-sticky {
  position: sticky;
  top: 100px;
}

.author-image {
  width: 100px;
  height: 100px;
  border: 4px solid #1c96f2;
}

.outline-list li a {
  cursor: pointer;
}

.outline-list li a.active {
  color: $blue;
  font-weight: 700;
}

.goal-box-inner ul li {
  position: relative;
  padding-left: 30px;
}

.goal-box-inner ul {
  margin-left: 20px;
}

.goal-box-inner ul li:before {
  position: absolute;
  left: 0;
  top: 2px;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: $blue;
}

.empty-content-box-icon {
  height: 200px;
}

.outline-list li {
  position: relative;
  padding-left: 16px;
}

.outline-list li:before {
  position: absolute;
  left: 0;
  top: 11px;
  width: 6px;
  height: 1px;
  background: #999;
  content: "";
}

.outline-list li,
.outline-list li a {
  line-height: 21px;
  display: inline-block;
}

.homepage-single-blog-meta-info .name-image .image {
  width: 50px;
  height: 50px;
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #4e4b66;
  flex-shrink: 0;
}

// .homepage-single-blog-meta-info .name-image .image {
//   width: 50px;
//   height: 50px;
//   box-shadow: 0 0 0 3px #fff, 0 0 0 5px #4e4b66;
// }

.header-menu-area ul li a .title {
  transition: 0.3s;
}

.header-menu-area ul li a:hover .title {
  color: $blue;
}

.section-outline {
  padding: 5px !important;
  width: 13%;
}
.blog-details-img {
  height: 240px;
  border-radius: 10px;
  overflow: hidden;
}

// coment box
.comment-box .first-comment {
  position: relative;
}
.comment-box .first-comment::before {
  position: absolute;
  content: "";
  background-color: rgb(216, 211, 211);
  height: 100%;
  width: 1px;
  bottom: 0;
}
.comment-box-2 .second-comment {
  position: relative;
}
.comment-box-2 .second-comment::before {
  position: absolute;
  content: "";
  background-color: rgb(216, 211, 211);
  height: 1px;
  width: 50px;
  bottom: 50%;
}
// new css custom
.services-area-feature {
  background-color: $white-dark-blue;
  margin: 50px 0 20px 0;
}

.services-area-feature::before {
  content: "";
  position: absolute;
  background: url(../public/images/bg-effect/download1.png) no-repeat center;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
}
.service-box-area-new {
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
}
.modules-area {
  background-color: #f3f7fa;
  margin: 50px 0 20px 0;
}

.modules-list-area {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 20px;
  gap: 2px;
}

.module-box-area-new {
  min-height: 400px;
}
// .modules-list-area {
//   background-color: rgb(243, 240, 240) !important;
// }
// .single-modules-list-area {
//   border: 1px solid rgb(240, 236, 236);
// }

.single-modules-list-area:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
// delivery service are
.delivery-service-image {
  height: 400px;
}
.delivery-box-area {
  display: flex;
  padding: 10px;
  margin-top: 50px;
}
.delivery-service-box-info-title h2,
p {
  margin: 0;
}
.delivery-service-box-info-title p {
  font-size: 24px;
  font-weight: 500;
  margin: 10px 0;
}
.delivery-service-box-info-item li {
  font-size: 20px;
  font-weight: 500;
  position: relative;
  margin-left: 30px;
  margin-top: 30px;
}
.delivery-service-box-info-item li::before {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-image: $gradient;
  top: 9px;
  left: -30px;
}
.delivery-service-box-info-item li p {
  font-size: 16px;
  color: #6e7191;
  line-height: 22px;
  margin-top: 5px;
  font-weight: 400;
}
.delivery-box-area:nth-child(even) {
  flex-direction: row-reverse;
}
.delivery-box-area:nth-child(even) .delivery-service-box-info-item li::before {
  display: none;
}

.delivery-box-area:nth-child(even) .delivery-service-box-info {
  text-align: end;
}
.delivery-box-area:nth-child(even) .delivery-service-box-info-item li {
  margin-right: 30px;
}
.delivery-box-area:nth-child(even) .delivery-service-box-info-item li::after {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-image: $gradient;
  top: 9px;
  right: -30px;
}
.delivery-box-area:nth-child(even) .delivery-service-box-image span img {
  object-position: left center !important;
}
.other-text-area .other-text-content {
  border: 3px solid transparent;
  border-image: linear-gradient(to right, #13dbd1, #038cc2) 1 50%;
}
.other-text-area .other-text-content p {
  text-align: justify;
}
.section-newsletter {
  padding: 0;
  width: 20%;
}
.newsletter-area {
  padding: 10px 7px;
  background-image: $gradient2;
  color: white;
}
.newsletter-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-image {
  width: 100px;
  height: 100px;
}
.newsletter-info {
  text-align: center;
}
.newsletter-info h6 {
  font-size: 21px;
  font-weight: 600;
}
.newsletter-info p {
  font-size: 14px;
  line-height: 20px;
}
.newsletter-input {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-input input {
  padding-left: 10px;
  border: none;
  background-color: rgb(243, 240, 240);
  border-radius: 15px;
  font-size: 12px;
  width: 210px;
}

.newsletter-input input::placeholder {
  font-size: 12px;
  padding: 10px;
  text-align: center;
}
.newsletter-input button {
  border: none;
  font-size: 14px;
  background-image: $gradient-hover;
  margin: 15px auto;
  display: block;
  padding: 0 10px;
  border-radius: 15px;
  color: white;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.newsletter-input button:hover {
  background-image: $gradient2;
}
// development company
.development-company-box-area {
  padding: 10px;
  margin-top: 50px;
}
.development-company-box-area .development-company-box-info {
  margin-top: 10px;
}
.development-company-box-area:nth-child(4) {
  flex-direction: row-reverse;
}

.development-company-box-area:nth-child(4) .development-company-box-info {
  text-align: end;
}

.development-company-box-area:nth-child(4) .development-company-box-image img {
  object-position: center !important;
}
.development-company-box-area .development-company-box-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.development-company-box-area .development-company-box-image {
  width: 250px;
  height: 100%;
}
.development-company-box-area .development-company-box-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.development-company-box-info-image-wrapper {
  margin-top: 20px;
  height: max-content;
  width: 100%;
}
.development-company-box-info-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
// feature count
.feature-count-area {
  padding: 40px 0;
}
.feature-count-area .hero-content .hero-title {
  font-size: 36px;
}
.feature-count-info-box {
  background-image: linear-gradient(
    to bottom,
    rgb(23, 134, 207),
    rgb(58, 191, 224)
  );
  color: white;

  border-radius: 15px;
  padding: 20px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.feature-count-info-box-image-wrapper {
  position: absolute;
  right: 5px;
  top: 10px;
}
.feature-count-info-box-image {
  width: 70px;
  height: 70px;
  padding: 2px;
}
.feature-count-info-box-image span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.feature-count-info-box h6 {
  text-transform: uppercase;
  font-size: 36px;
  margin-bottom: 5px !important;
}
.feature-count-info-box p {
  font-size: 16px;
  text-transform: capitalize;
}

.blog-title {
  font-family: "Noto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  line-height: 1.2;
  font-size: 4rem;
  color: #000;
  // border-right: 2px solid rgba(255, 255, 255, 0.2); // Add right border only
}

.blog-content {
  font-family: "Noto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  color: #1f1f1f;
  line-height: 1.85;
  font-size: 1.2rem;
}

.blog-content a {
  color: blue;
}

.blog-content h2 {
  color: #055681; // Fallback for older browsers
  background: linear-gradient(45deg, #055681, #0c89cc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  padding-left: 1.5rem;

  &:before {
    content: "🔗";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    -webkit-text-fill-color: #055681; // Reset the gradient effect for the icon
  }

  &:hover {
    color: #055681;
    cursor: pointer;
  }
}

.toc-container {
  position: sticky;
  top: 60px;
  padding: 20px;
  border-radius: 8px;
  background: #0641fa1c;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.toc-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0163fb;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #0265c8;
}

.toc-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.toc-list li {
  margin-bottom: 0.75rem;
  position: relative;
  padding-left: 16px;
}

.toc-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 6px;
  height: 2px;
  background: #6e7191;
  transition: all 0.3s ease;
}

.toc-link {
  color: #495057;
  text-decoration: none;
  font-size: 0.95rem;
  line-height: 1.4;
  display: block;
  padding: 4px 0;
  transition: color 0.2s ease;

  &:hover {
    color: #0d6efd;
    text-decoration: none;
  }

  &:hover:before {
    background: #0d6efd;
    width: 8px;
  }
}

// Active state for current section
.toc-link.active {
  color: #0d6efd;
  font-weight: 500;
}

.toc-list li:hover:before {
  background: #0d6efd;
  width: 8px;
}

.blog-author {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6e7191;
  font-size: 0.9rem;

  &:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #6e7191;
    border-radius: 50%;
    margin-right: 4px;
  }
}
